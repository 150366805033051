import React from 'react';

import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DescriptionIcon from '@material-ui/icons/Description';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupIcon from '@material-ui/icons/Group';
import StoreIcon from '@material-ui/icons/Store';
import GrainIcon from '@material-ui/icons/Grain';
import CropFreeIcon from '@material-ui/icons/CropFree';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

export default [
  {
    key: 'payroll',
    path: '/payroll',
    name: 'Disbursement',
    icon: <AccountBalanceWalletIcon />,
    children: [
      {
        key: 'payroll.transfer-payroll',
        path: '/payroll/transfer-payroll',
        icon: <SwapHorizIcon />,
        name: 'Request',
      },
      {
        key: 'payroll.payroll-history',
        path: '/payroll/payroll-history',
        icon: <AssessmentIcon />,
        name: 'History',
      },
    ],
  },
  {
    key: 'qris',
    path: '/qris',
    name: 'QRIS',
    icon: <GrainIcon />,
    children: [
      {
        key: 'qris.transactions',
        path: '/qris/transactions',
        icon: <FormatListBulletedIcon />,
        name: 'Transactions',
      },
      {
        key: 'qris.generate',
        path: '/qris/generate',
        icon: <CropFreeIcon />,
        name: 'Generate QR',
      },
      {
        key: 'qris.scan',
        path: '/qris/scan',
        icon: <CenterFocusStrongIcon />,
        name: 'Scan QR',
      },
    ],
  },
  {
    key: 'transaction-history',
    path: '/transaction-history',
    name: 'Transaction History',
    icon: <DescriptionIcon />,
    children: [],
  },
  {
    key: 'settings',
    path: '/settings',
    name: 'Settings',
    icon: <SettingsIcon />,
    children: [
      {
        key: 'settings.user',
        path: '/settings/user',
        icon: <GroupIcon />,
        name: 'User',
      },
      {
        key: 'settings.merchant',
        path: '/settings/merchant',
        icon: <StoreIcon />,
        name: 'Merchant',
      },
    ],
  },
];
