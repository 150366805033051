import { isRouteValid } from 'src/utils/roles-data-converter';
import store from '../redux';

// KYC
export const kycReject = 'account-verification.account-verification-request.reject';
export const kycSave = 'account-verification.account-verification-request.save';
export const kycApprove = 'account-verification.account-verification-request.approve';
// USER MANAGE
export const userEdit = 'settings.manage-users.edit';
export const userBlock = 'settings.manage-users.block';
export const userUnblock = 'settings.manage-users.unblock';
export const userDeactivate = 'settings.manage-users.deactivate';
export const userReinvite = 'settings.manage-users.reinvite';

export default function findRolePermission(key) {
  const { auth } = store.getState();
  const { treeRole } = auth.userData;
  return isRouteValid(treeRole, key, ['/']);
}
