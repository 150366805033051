import async from '../components/atoms/async';
// import PayrollList from "../components/pages/payroll-list";

const Payroll = async(() => import('src/components/pages/payroll-list'));
const PayrollHistory = async(() => import('src/components/pages/payroll-history'));
const PayrollHistoryDetail = async(() => import('src/components/pages/payroll-history-detail'));

const payroll = [
  {
    auth: true,
    path: '/payroll/transfer-payroll',
    key: 'payroll.transfer-payroll',
    layout: 'sidebar',
    component: Payroll,
  },
  {
    auth: true,
    path: '/payroll/payroll-history',
    key: 'payroll.payroll-history',
    layout: 'sidebar',
    component: PayrollHistory,
  },
  {
    auth: true,
    path: '/payroll/payroll-history/detail/:file_name',
    key: 'payroll.payroll-history.detail',
    layout: 'sidebar',
    component: PayrollHistoryDetail,
  },
];

export default payroll;
