import React from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {
  componentDidUpdate() {
    // if (this.props.location !== prevProps.location) {
    //   window.scrollTo(0, 0);
    // }
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment,react/prop-types
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
