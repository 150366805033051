import async from '../components/atoms/async';

const Setting = async(() => import('src/components/pages/user-list'));
const UserForm = async(() => import('src/components/pages/user-form'));
const MerchantList = async(() => import('src/components/pages/merchant-list'));

const manageUsers = [
  {
    auth: true,
    path: '/settings/user',
    key: 'settings.user',
    layout: 'sidebar',
    component: Setting,
  },
  {
    auth: true,
    path: '/settings/user/add',
    key: 'settings.user.add',
    layout: 'sidebar',
    component: UserForm,
    title: 'create_user',
  },
  {
    auth: true,
    path: '/settings/user/edit/:user_name',
    key: 'settings.user.add',
    layout: 'sidebar',
    component: UserForm,
    title: 'edit_user',
  }, {
    auth: true,
    path: '/settings/merchant',
    key: 'settings.merchant',
    layout: 'sidebar',
    component: MerchantList,
    title: 'edit_user',
  },
];

export default manageUsers;
