import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { INode } from '../../../types/common';
import './index.scss';

export interface PropTypes extends DialogProps{
    component?: INode;
    header?: string;
    hasHeader?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    onClose: () => void;
}

const ModalStateless: React.FunctionComponent<PropTypes> = ({
  component: Component,
  header,
  hasHeader,
  onClose,
  open,
  size,
  ...rest
}: PropTypes) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth={size}
    {...rest}
  >
    {
            hasHeader && (
            <>
              <DialogTitle>
                <Grid container>
                  <Grid item md={11}>
                    <p className="title">{ header }</p>
                  </Grid>
                  <Grid item md={1}>
                    <button
                      className="btn-close"
                      type="button"
                      onClick={onClose}
                    >
                      <CloseIcon />
                    </button>
                  </Grid>
                </Grid>
              </DialogTitle>
            </>
            )
        }
    {Component}
  </Dialog>
);

export default ModalStateless;
