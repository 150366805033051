import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { compose } from 'redux';
// Material UI
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
// other necessities
import userDefault from 'src/assets/img/icon/profile.png';
import { ReactComponent as VirgoBizJade } from 'src/assets/img/logo/logo_virgo_biz_jade.svg';
import menus from 'src/components/layouts/menu';
import findRolePermission from 'src/utils/role';
import { randomString } from 'src/utils/string';
import { IRouteHistory, IRouteLocation, IRouteMatch } from 'src/types/router';
import { IState } from 'src/types/state';
import makeCustomStyles from './style';
import './_index.scss';
import { IUserData } from '../../../types/user/http';
import convertRoleName from '../../../utils/convert-role';

interface listPropsType {
  [key: string]: boolean;
}

interface propsType {
  match?: IRouteMatch;
  location?: IRouteLocation;
  history?: IRouteHistory;
  role?: string[];
  roleName?: string;
  loading?: boolean;
  user?:IUserData;
  onNavItemPress: () => void,
}

const collapseBuilder = (menuList: any[]): listPropsType => {
  let formattedCollapse = {};
  for (let index = 0; index < menuList.length; index += 1) {
    const menu = menuList[index];
    formattedCollapse = Object.assign(formattedCollapse, {
      [menu.key]: false,
    });
  }
  return formattedCollapse;
};

const onOpenSubMenu = (key, isOpen, openCb) => {
  openCb({
    [key]: !isOpen[key],
  });
};

const Sidebar: React.FunctionComponent<propsType> = (props: propsType) => {
  const {
    role,
    roleName,
    location: { pathname = '' },
    loading,
    history,
    user,
    onNavItemPress,
  } = props;

  // Open list state
  const [isOpen, setOpen] = useState<listPropsType>(collapseBuilder(menus));
  const styles = makeCustomStyles();

  const handleOnPressedNavItem = (navigationLink) => {
    onNavItemPress();
    history.push(navigationLink);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      classes={{
        root: styles.listRoot,
      }}
    >
      <ListItem>
        <div className="list_logo">
          <VirgoBizJade />
        </div>
      </ListItem>
      <ListItem>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          className={styles.box}
        >
          <Grid container style={{ marginLeft: '10px' }}>
            <Grid item className={styles.profilePicture}>
              <img alt="user" className="image-profile" src={userDefault} />
            </Grid>
            <Grid className="ellipsis" item style={{ paddingLeft: '5px', maxWidth: '145px' }}>
              {user.name}
              <br />
              <b className="f--size__12 ellipsis">{convertRoleName(roleName)}</b>
            </Grid>
          </Grid>
        </Box>
      </ListItem>
      {
        loading && (
          <>
            {
              [...Array(4)].map(() => (
                <span key={randomString()} className="sidebar_skeleton-wrapper flex ml-2">
                  <Skeleton classes={{ root: styles.skeletonRoot }} variant="circle" width={30} height={30} />
                  <Skeleton classes={{ root: styles.skeletonRoot }} variant="rect" height={30} width="75%" />
                </span>
              ))
            }
          </>
        )
      }
      <div className="sidebar_item-wrapper">
        {
          !loading && role && role.length !== 0 && menus.map((menu) => (
            <React.Fragment key={menu.path}>
              {findRolePermission(menu.key)
                ? (() => {
                  if (menu.children) {
                    return (
                      <ListItem
                        button
                        onClick={() => onOpenSubMenu(menu.key, isOpen, setOpen)}
                      >
                        <ListItemIcon
                          className={pathname.includes(menu.path) ? styles.listIconRootActive
                            : styles.listIconRoot}
                        >
                          {menu.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={menu.name}
                          classes={{
                            primary: pathname.includes(menu.path) ? styles.listTextPrimaryActive
                              : styles.listTextPrimary,
                          }}
                        />
                        {(isOpen[menu.key] || pathname.includes(menu.path))
                          ? (
                            <ExpandLess classes={{
                              root: pathname.includes(menu.path)
                                ? styles.expandable
                                : null,
                            }}
                            />
                          )
                          : <ExpandMore />}
                      </ListItem>
                    );
                  }
                  return (
                    <ListItem>
                      <ListItemIcon className={styles.listIconRoot}>
                        {menu.icon}
                      </ListItemIcon>
                      <NavLink
                        to={menu.path}
                        style={{
                          textDecoration: 'none',
                        }}
                      >
                        <ListItemText
                          primary={menu.name}
                          classes={{
                            primary: styles.listTextPrimary,
                          }}
                        />
                      </NavLink>
                    </ListItem>
                  );
                })()
                : null}
              {menu.children.map((child) => (
                <Collapse
                  key={child.path}
                  in={isOpen[menu.key] || pathname.includes(menu.path)}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {findRolePermission(child.key) && (
                    <ListItem
                      button
                      classes={{
                        root: pathname.includes(child.path)
                          ? styles.nestedActive
                          : styles.nested,
                      }}
                      onClick={() => handleOnPressedNavItem(child.path)}
                    >
                      <ListItemIcon
                        className={pathname.includes(child.path) ? styles.listIconSubRootActive
                          : styles.listIconSubRoot}
                      >
                        {child.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={child.name}
                        classes={{
                          primary: pathname.includes(child.path)
                            ? styles.subListTextPrimaryActive
                            : styles.subListTextPrimary,
                        }}
                      />
                    </ListItem>
                    )}
                  </List>
                </Collapse>
              ))}
            </React.Fragment>
          ))
        }
      </div>
    </List>
  );
};

const mapStateToProps = (state: IState = {}) => ({
  sidebar: state.app,
  role: state.auth.userData.role,
  roleName: state.auth.userData.roleName,
  loading: state.auth.loading.role,
  user: state.auth.userData,
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect, React.memo)(withRouter(Sidebar));
