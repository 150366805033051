export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const MAP_USER_DATA = 'MAP_USER_DATA';

export const GET_USER_ROLE = 'GET_USER_ROLE';
export const MAP_USER_ROLE = 'MAP_USER_ROLE';

export const ADD_MERCHANT_LOGO = 'ADD_MERCHANT_LOGO';
