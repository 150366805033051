import React from 'react';
import SiginForm from 'src/components/organisms/form-sign-in';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { ReactComponent as ComputerPicture } from 'src/assets/img/picture/computer.svg';
import { ReactComponent as VirgoBiz } from 'src/assets/img/logo/logo_virgo_biz.svg';

import './index.scss';

const useStyles = () => makeStyles((theme: Theme) => ({
  box: {
    backgroundColor: theme.palette.customPrimary.main,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoVirgo: {
    position: 'absolute',
    top: 0,
  },
}));

const PageLogin = () => {
  const classes = useStyles()();
  return (
    <Grid
      container
      justify="center"
    >
      <Grid item md={3}>
        <Box height="100vh" className={classes.box}>
          <VirgoBiz className={classes.logoVirgo} />
          <Paper className="tagline-section">
            <ComputerPicture />
            <h2 className="tagline-text">A few clicks away to generate your disbursement process</h2>
          </Paper>
        </Box>
      </Grid>
      <Grid item md={9}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          maxWidth="450px"
        >
          <div>
            <h2 className="head-title"><b>Welcome to Virgo for business</b></h2>
            <p className="lead" style={{ marginBottom: '25px' }}>
              Disbursement Platform
            </p>
            <div className="login_card">
              <SiginForm />
            </div>
          </div>
        </Box>
      </Grid>
    </Grid>

  );
};

export default React.memo(PageLogin);
