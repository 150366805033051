import React from 'react';
import classNames from 'classnames';

interface PropsType {
  className?: string;
  children?: React.ReactElement<any> | Element | string;
}

const Wrapper: React.FunctionComponent<PropsType> = ({
  // eslint-disable-next-line react/prop-types
  className = '',
  // eslint-disable-next-line react/prop-types
  children = null,
}) => (
  <div className={classNames('wrapper', className)}>{children}</div>
);

export default React.memo(Wrapper);
