import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const makeCustomStyles = makeStyles((theme: Theme) => createStyles({
  listRoot: {},
  listTextPrimary: {
    fontSize: '15px',
    color: theme.palette.customMistyVariant.darkest,
  },
  listTextPrimaryActive: {
    fontSize: '15px',
    color: theme.palette.customPrimary.main,
    fontWeight: 'bold',
  },
  subListTextPrimary: {
    fontSize: '15px',
    color: theme.palette.customMistyVariant.darkest,
  },
  subListTextPrimaryActive: {
    fontSize: '15px',
    color: 'white',
    fontWeight: 'bold',
  },
  expandable: {
    color: theme.palette.customPrimary.main,
  },
  listIconRoot: {
    minWidth: 40,
  },
  listIconRootActive: {
    minWidth: 40,
    color: theme.palette.customPrimary.main,
  },

  listIconSubRoot: {
    minWidth: 40,
  },
  listIconSubRootActive: {
    minWidth: 40,
    color: 'white',
  },
  nested: {
    paddingLeft: theme.spacing(7),
  },
  nestedActive: {
    paddingLeft: theme.spacing(7),
    backgroundColor: theme.palette.customPrimary.darkest,
  },
  skeletonRoot: {
    margin: '5px',
  },
  profilePicture: {
    backgroundColor: theme.palette.customPrimary.main,
    maxWidth: '24px',
    height: '24px',
    marginTop: '4px',
    borderRadius: '100px',
  },
  box: {
    marginTop: '20px',
    borderRadius: '16px',
    padding: '12px 8px',
    backgroundColor: theme.palette.customMistyVariant.light,
  },
}));

export default makeCustomStyles;
