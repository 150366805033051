// import user from './user';
import payroll from './payroll';
import qris from './qris';
import setting from './setting';

export default [
  ...payroll,
  ...qris,
  ...setting,
];
