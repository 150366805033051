import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const PageLoading = () => (
  <div>
    <Skeleton style={{ marginTop: '50px' }} variant="text" height={40} width="30%" />
    <Skeleton variant="text" height={30} width="20%" />
    <Skeleton style={{ marginTop: '40px' }} variant="rect" height={250} width="100%" />
  </div>
);

export default PageLoading;
