import {
  admin, approver, requester, superAdmin, qris,
} from './constant';

export default function convertRoleName(role) {
  switch (role) {
    case 'requester':
      return requester;
    case 'approver':
      return approver;
    case 'admin':
      return admin;
    case 'superAdmin':
      return superAdmin;
    case 'qris':
      return qris;
    default: return '';
  }
}
