import { RoleNode } from 'src/types/role/role-node.model';

const convertRoleArrayToRoleTree = (data: string[]): RoleNode => {
  const result: RoleNode[] = [];
  data.forEach((s) => s.split('.').reduce(
    (object: RoleNode, value: string) => {
      // eslint-disable-next-line no-param-reassign
      let item = (object.children = object.children || []).find(
        (q) => q.value === value,
      );
      if (!item) {
        object.children.push((item = { value }));
      }
      return item;
    },
    { children: result },
  ));

  return {
    children: result,
  };
};

const isRouteValid = (
  roleTree: RoleNode,
  role: string,
  excludedRoles: string[],
): boolean => {
  // check if role is not valid
  if (role === undefined) {
    return false;
  }

  // check is roleTree is ready
  if (Object.keys(roleTree).length === 0) {
    return false;
  }

  // check is excluded role
  const isRoleExcluded = excludedRoles.indexOf(role);
  if (isRoleExcluded > -1) {
    return true;
  }

  const roleFragments = role.split('.');
  let currentRoleTree = roleTree;

  // eslint-disable-next-line no-restricted-syntax
  for (const roleFragment of roleFragments) {
    // eslint-disable-next-line no-loop-func
    if (currentRoleTree.children) {
      const currentRoleNode = currentRoleTree.children.filter(
        // eslint-disable-next-line no-loop-func
        (item: RoleNode) => {
          if (item.value === roleFragment) {
            currentRoleTree = item;
          }

          return item.value === roleFragment;
        },
      );
      if (currentRoleNode.length === 0) {
        return false;
      }
    }
  }

  return true;
};

export { convertRoleArrayToRoleTree, isRouteValid };
