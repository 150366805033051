import React from 'react';
import { toastr } from 'react-redux-toastr';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as Exit } from 'src/assets/img/icon/exit.svg';
import Button from 'src/components/atoms/button';
import jwtDecode from 'src/utils/jwt';
import { doLogout } from 'src/services/auth';
import { getToken, removeToken, removeUid } from 'src/utils/local-storage';
import './_index.scss';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IState } from '../../../types/state';

interface PropTypes {
  logo: string
}

const NavbarComponent: React.FunctionComponent<PropTypes> = (props: PropTypes) => {
  const {
    logo,
  } = props;
  // ACTION
  let userEmail = '';
  const token = getToken();
  const tokenDecode = jwtDecode(token);
  if (token) {
    userEmail = tokenDecode && tokenDecode.username;
  }
  const doSignOut = () => {
    doLogout(userEmail).then((res: any) => {
      toastr.success(res.message);
    }).catch((res) => {
      toastr.warning(res.message);
    });
    window.location.replace('/');
    removeUid();
    removeToken();
  };

  return (
    <div className="navbar flex v-center">
      <Grid
        container
      >
        <Grid
          item
          md={9}
        >
          <div className="flex merchant-brand">
            {
              logo !== ''
              && <img style={{ height: '80px' }} alt="..." src={logo} />
            }
          </div>
        </Grid>
        <Grid item md={3}>
          <div style={{ height: '100%' }} className="flex v-center direction-reverse">
            <Button
              style={
                {
                  borderRadius: '10px',
                  boxShadow: 'none',
                  color: 'black',
                  textTransform: 'none',
                }
              }
              onClick={doSignOut}
              className="f--size__14"
              color="misty-light"
              endIcon={<Exit />}
            >
              Log out
            </Button>
          </div>
        </Grid>
      </Grid>

    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  logo: state.auth.userData.logo,
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect, React.memo)(NavbarComponent);
