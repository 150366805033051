import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { INode } from '../../../types/common';

interface PropTypes {
  type?: 'text' | 'password' | 'number';
  id?: string;
  label?: string;
  name?: string;
  value?: string | number;
  handleChange?: (event?: any, newValue?: string) => void | any;
  handleBlur?: (event?: any) => void | any;
  showPass?: boolean;
  startIcon?: INode;
  classVisible?: any;
  togglePass?: (event?: any) => void | any;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  defaultValue?: any;
  placeholder?: string,
  className?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  inputComponentCustom?: INode;
}

const styles = makeStyles({
  root: {
    marginBottom: '0px',
    marginTop: '0px',
    fontFamily: 'Montserrat Regular !important',
    '& .Mui-focused': {
      color: '#868686',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid #868686 !important',
    },
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat Regular !important',
    },
  },
});

const Input: React.FunctionComponent<PropTypes> = ({
  type = 'text',
  id = '',
  label = '',
  name = '',
  handleChange = () => {},
  handleBlur = () => {},
  showPass = false,
  classVisible = {},
  error = false,
  errorMessage = '',
  disabled = false,
  defaultValue = null,
  className = '',
  startIcon = '',
  value = null,
  variant = 'outlined',
  placeholder = '',
  // inputComponentCustom = <InputBase />,
}: PropTypes) => {
  const [showPassVisibility, setPassVisibility] = React.useState(false);
  // OTHER
  const classes = styles();
  const valueProps = value || value === '' ? { value } : {};
  const defValueProps = defaultValue || defaultValue === '' ? { defaultValue } : {};
  const inputType = showPass && showPassVisibility ? 'text' : 'password';

  // @ts-ignore
  return (
    <>
      <TextField
        classes={{
          root: classes.root,
        }}
        className={className}
        variant={variant}
        margin="normal"
        size="small"
        fullWidth
        type={showPass ? inputType : type}
        id={id}
        label={label}
        name={name}
        placeholder={placeholder}
        onChange={(e) => {
          // [NOTE] REACT <= 16 EVENT POOLING https://reactjs.org/docs/legacy-event-pooling.html
          e.persist();
          handleChange(e);
        }}
        onKeyUp={handleBlur}
        error={error}
        InputProps={{
          startAdornment: startIcon,
          endAdornment:
            showPass ? (
              <InputAdornment
                position="end"
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setPassVisibility(!showPassVisibility)}
                  classes={classVisible}
                >
                  {!showPassVisibility
                    ? <VisibilityOutlined />
                    : <VisibilityOffOutlined />}
                </IconButton>
              </InputAdornment>
            ) : null,
        }}
        helperText={error ? errorMessage : null}
        disabled={disabled}
        {...valueProps}
        {...defValueProps}
      />
    </>
  );
};

export default Input;
