import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import Paper from '@material-ui/core/Paper';
// import InputLabel from '@material-ui/core/InputLabel';
import Input from 'src/components/atoms/input-new';
import Typography from '@material-ui/core/Typography';
import { makeStyles, fade } from '@material-ui/core/styles';
import Button from 'src/components/atoms/button';
import { doForgotPass } from 'src/services/auth';
import { ReactComponent as ArtEmail } from 'src/assets/img/picture/art_email.svg';
import './_index.scss';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { openSnackbar, toggleModal } from '../../../redux/app/actions';

interface PropTypes {
  onClose?: (event?: any) => void | any;
}

const styles = makeStyles({
  rootInput: {
    width: '100%',
  },
  customInput: {
    marginTop: 5,
    fontSize: '1.125rem',
    padding: '5px 15px',
    lineHeight: '1.5',
    borderRadius: '.3rem',
    position: 'relative',
    background: 'none',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    width: '100%',
    height: 25,
    '&:focus': {
      backgroundColor: '#fff',
      boxShadow: `${fade('#153d77', 0.25)} 0 0 0 2px`,
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  formControl: {
    width: '100%',
    marginTop: 20,
  },
  paper: {
    height: '100%',
    padding: '20px 0',
  },
  rootPaper: {
    minWidth: '340px',
    padding: '0px 30px',
  },
  button: {
    fontFamily: 'Montserrat Regular',
  },
});

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
});

const ModalForgotPassword: React.FunctionComponent<PropTypes> = ({
  onClose = () => {},
}: PropTypes) => {
  const classes = styles();
  const [loading, setLoading] = useState(false);
  const [isSubmitDisabled, setDisableSubmit] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useDispatch();
  const handleForgotPass = ({ email }) => {
    setLoading(true);
    doForgotPass(email).then((res: any) => {
      if (res) {
        setIsSuccess(true);
      } else {
        dispatch(openSnackbar({
          type: 'warning',
          message: res.message,
        }));
      }
      setLoading(false);
    }).catch((res) => {
      dispatch(openSnackbar({
        type: 'warning',
        message: res.message,
      }));
      setLoading(false);
    });
  };
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      handleForgotPass(values);
    },
  });
  const onCloseModal = () => {
    dispatch(toggleModal({
      open: false,
    }));
  };
  useEffect(() => {
    if (formik.values.email !== '') {
      if (formik.isValid) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    }
  }, [formik]);
  return (
    <div className="m-sm-4 paper-login">
      <Grid container className="mt-8 mb-4">
        <Grid item md={11}>
          {
            !isSuccess
            && <p style={{ paddingTop: '5px', fontSize: '16px' }} className="title">Forgot your password?</p>
          }
        </Grid>
        <Grid item md={1}>
          <button
            className="btn-close"
            type="button"
            onClick={onCloseModal}
          >
            <CloseIcon />
          </button>
        </Grid>
      </Grid>
      {
        isSuccess
          ? (
            <div className="text-center">
              <ArtEmail />
              <p className="title">Check Your Email!</p>
              <p className="tagline-forgot-password">We have sent a link to reset your password</p>
            </div>
          )
          : (
            <>
              <p className="tagline-forgot-password">Enter your email below to reset your password</p>
            </>
          )
      }
      <form onSubmit={formik.handleSubmit}>
        {
          !isSuccess
          && (
          <Input
            id="email"
            type="text"
            label="Input your email"
            name="email"
            variant="standard"
            value={formik.values.email}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={formik.errors.email && formik.touched.email}
            errorMessage={formik.errors.email}
          />
          )
        }

        {isSuccess ? (
          <div className="text-center mt-8 mb-8">
            <Button
              type="button"
              onClick={onClose}
              className={classes.button}
              disabled={loading || isSubmitDisabled}
              loading={loading}
            >
              <Typography className="label-button">Ok</Typography>
            </Button>
          </div>
        ) : (
          <div className="mt-8 mb-8 flex h-end">
            <Button
              type="submit"
              className={classes.button}
              disabled={loading || isSubmitDisabled}
              loading={loading}
            >
              <Typography className="label-button">Submit</Typography>
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

export default ModalForgotPassword;
