/**
* Utilities to fetch data when the state is login
* @module fetcher
* @param { oneOf[ get, post, put, delete] } Request method
* @param { string } urlPath
* @param { object } body
* @returns { object } JSON
 */

// import jwtDecode from 'src/utils/jwt';
import { getToken, removeToken, removeUid } from 'src/utils/local-storage';
import dayjs from 'dayjs';
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import utc from 'dayjs/plugin/utc';
import jsonminify from 'jsonminify';

export const ERROR_NO_CONNECTION = ['Sorry there is problem. Pleas refresh again.'];
export const RESPONSE_SUCCESS = 'SUCCESS';
export const RESPONSE_ERROR = 'ERROR';
export const RESPONSE_ERROR_CONNECTION = { status: RESPONSE_ERROR, errors: ERROR_NO_CONNECTION };
export const BASE_URL = process.env.REACT_APP_BASE_URL_API;

export const responseError = (errors) => ({
  status: RESPONSE_ERROR,
  errors,
});

dayjs.extend(utc);

const requestHeaders = (
  isFile?: boolean,
  sign?: boolean,
  method?: string,
  urlPath?: string,
  params?,
) => {
  const token = getToken() || false;

  const Headers: any = { headers: {} };

  if (!isFile) {
    Headers.headers.Accept = 'application/json';
  }

  if (token) {
    Headers.headers.authorization = `Bearer ${token}`;
  }

  if (sign) {
    const timeStamp = dayjs().utc().format();

    const exClientSecret = 'GBAyfVL7YWtP6gudLIjbRZV_N0dW4f3xETiIxqtokEAZ6FAsBtgyIq0MpU1uQ7J08xOTO2zwP0OuO3pMVAUTid';

    const minifyParam = jsonminify(JSON.stringify(params));
    const encodedHex = Buffer.from(sha256(minifyParam.toString()).toString()).toString('hex');
    const stringToSign = `${method}:${urlPath}:${token}:${(encodedHex).toLowerCase()}:${timeStamp}`;

    const msg = new TextEncoder().encode(exClientSecret);
    Headers.headers['X-TIMESTAMP'] = timeStamp;
    Headers.headers['X-SIGNATURE'] = hmacSHA512(msg, stringToSign);
    Headers.headers['Content-Type'] = 'application/json';
  }

  return Headers;
};

function handleResponseFile(response) {
  return response.blob();
}

function handleResponse(response, type: 'json' | 'text' = 'json', isFile?) {
  let transformedRes;
  if (type === 'text' || response.status === 401) {
    transformedRes = response.text();
  } else {
    transformedRes = response.json();
  }
  return transformedRes
    .then((data) => {
      let transformedData = data;
      if (type === 'text') {
        transformedData = data && JSON.parse(data);
      }
      if (!response.ok) {
        if (response.status === 401) {
          // REMOVE DATA FROM LOCAL STORAGE
          removeToken();
          removeUid();
          window.location.replace('/');
        }
        if (isFile) {
          return Promise.reject(transformedData || response.statusText);
        }
        if (response.status === 500) {
          return Promise.reject(data);
        }
        throw new Error(JSON.stringify({
          ...data,
          status: response.status,
        }));
      }
      return transformedData;
    });
}

export const fetcher = {
  get: (urlPath, isFile?: boolean, type?) => fetch(BASE_URL + urlPath, requestHeaders())
    .then((res) => {
      if (isFile) {
        return handleResponseFile(res);
      }
      return handleResponse(res, type);
    })
    .then((result) => result)
    .catch((error) => Promise.reject(error)),

  post: (urlPath, params?, isFile?: boolean, type?, sign?: boolean) => fetch(BASE_URL + urlPath, {
    ...requestHeaders(isFile, sign, 'POST', urlPath, params),
    body: isFile ? params : JSON.stringify(params),
    method: 'POST',
  })
    .then((res) => handleResponse(res, type, isFile))
    .then((result) => result)
    .catch((error) => Promise.reject(error)),

  put: (urlPath, params?, isFile?: boolean, type?) => fetch(BASE_URL + urlPath, {
    ...requestHeaders(isFile),
    body: isFile ? params : JSON.stringify(params),
    method: 'PUT',
  })
    .then((res) => handleResponse(res, type, isFile))
    .then((result) => result)
    .catch((error) => Promise.reject(error)),

  delete: (urlPath, params?, type?) => fetch(BASE_URL + urlPath, {
    ...requestHeaders(),
    body: JSON.stringify(params),
    method: 'DELETE',
  })
    .then((res) => handleResponse(res, type))
    .then((result) => result)
    .catch((error) => Promise.reject(error)),

  put_gstorage: (urlPath, params, contentType = 'text/csv') => fetch(urlPath, {
    headers: {
      'Content-Type': contentType,
    },
    body: params,
    method: 'PUT',
  })
    .then((result) => result)
    .catch((error) => Promise.reject(error)),

  get_csv: (urlPath) => fetch(BASE_URL + urlPath, requestHeaders())
    .then((response) => response.text())
    .catch((error) => Promise.reject(error)),

};
