import { connect } from 'react-redux';
import { compose } from 'redux';
import React from 'react';
import { ISnackbar } from 'src/types/app/state';
import SnackbarStateless from './snackbar-stateless';

interface PropTypes {
  snackbar: ISnackbar
}

const Snackbar: React.FunctionComponent<PropTypes> = ({ snackbar }: PropTypes) => (
  <SnackbarStateless
    {...snackbar}
  />
);

const mapStateToProps = (state) => ({
  snackbar: state.app.snackbar,
});

const withConnect = connect(mapStateToProps, null);

const withCompose = compose(withConnect, React.memo);

export default withCompose(Snackbar);
