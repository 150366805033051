import { fetcher } from 'src/services';
import { IHttpResponse } from 'src/types/service/http';
import { ILoginResponse, IPermissionResponse } from 'src/types/auth/http';

const doCheckToken = async (token) => {
  try {
    const response: IHttpResponse<any> = await fetcher.get(`/r/user/invite?token=${token}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const doCreatePass = async (password, token) => {
  try {
    const response: IHttpResponse<any> = await fetcher.put('/r/user/disbursement/new-password', ({
      mail_token: token,
      password,
    }));
    return response;
  } catch (error) {
    throw error;
  }
};

const doCheckResetPassToken = async (token) => {
  try {
    const response: IHttpResponse<any> = await fetcher.get(`/r/user/password-reset?token=${token}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const doResetPass = async (token, password) => {
  try {
    const response: IHttpResponse<any> = await fetcher.put(`/r/user/disbursement/password-reset?token=${token}`, ({
      password,
    }));
    return response;
  } catch (error) {
    throw error;
  }
};

const doLogin = async (email, password) => {
  try {
    const response: IHttpResponse<ILoginResponse> = await fetcher.post('/r/user/disbursement/login', ({
      user_name: email,
      password,
    }));
    return response;
  } catch (error) {
    throw error;
  }
};

const getPermission = async (userId: number | string) => {
  try {
    const response: IPermissionResponse = await fetcher.get(
      `/r/user/disbursement/${userId}/permissions`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getBrandLogo = async () => {
  try {
    return await fetcher.get(
      '/r/customer/disbursement/merchant/icon',
    );
  } catch (error) {
    throw error;
  }
};

const doLogout = async (email) => {
  try {
    const response: IHttpResponse<any> = await fetcher.post('/r/user/logout', ({
      user_name: email,
    }));
    return response;
  } catch (error) {
    throw error;
  }
};

const doForgotPass = async (email) => {
  try {
    const response: IHttpResponse<any> = await fetcher.post('/r/user/disbursement/password-reset', ({
      email,
    }));
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  doCheckToken,
  doCreatePass,
  doCheckResetPassToken,
  doResetPass,
  doLogin,
  doLogout,
  doForgotPass,
  getPermission,
  getBrandLogo,
};
