import async from '../components/atoms/async';

const GenerateQr = async(() => import('src/components/pages/qris-generate'));
const ScanQr = async(() => import('src/components/pages/qris-scan'));
const QrisTransactions = async(() => import('src/components/pages/qris-transactions'));
const QrisTransactionsDetail = async(() => import('src/components/pages/qris-transactions-detail'));

const qris = [
  {
    auth: true,
    path: '/qris/generate',
    key: 'qris.generate',
    layout: 'sidebar',
    component: GenerateQr,
  },
  {
    auth: true,
    path: '/qris/scan',
    key: 'qris.scan',
    layout: 'sidebar',
    component: ScanQr,
  },
  {
    auth: true,
    path: '/qris/transactions',
    key: 'qris.transactions',
    layout: 'sidebar',
    component: QrisTransactions,
  },
  {
    auth: true,
    path: '/qris/transactions/detail/:trx_type/:id',
    key: 'qris.transaction.detail',
    layout: 'sidebar',
    component: QrisTransactionsDetail,
  },
];

export default qris;
