import { v4 as uuidv4 } from 'uuid';
import Querystring from 'query-string';

const parseToQueryString = (obj: any) => Object
  .keys(obj)
  .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
  .join('&');

export const randomString = () => uuidv4();

export const parseFromQueryString = (querystring: string) => Querystring.parse(querystring);

export default parseToQueryString;
